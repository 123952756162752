@charset "utf-8";
/* mobile */
/* z-index (.modal : 10) (.nav-wrap : 11) */

.create_game {
  .content_wrap {
    padding-bottom: 98px;
    form {
      margin-top: 10px;
      & > div {
        padding: 30px 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        label {
          font-weight: 600;
          span {
            color: red;
          }
        }
      }
      .schedule {
        input {
          height: 23px;
          padding: 8.5px 14px;
          border: none;
          border: 1px solid #aaa;
          border-radius: 4px;
        }
        input:focus {
          outline: none;
          border: solid 1px #6269f5;
          border-radius: 4px;
          box-sizing: content-box;
        }
      }
      .matchtime {
        input {
          text-align: right;
        }
      }
      .versus {
        button {
          height: 40px;
          text-transform: none;
          background-color: transparent;
          border: 1px solid #aaa;
          color: #000;
          cursor: pointer;
        }
        .on {
          background-color: #6269f5;
          color: #fff;
        }
      }
      .people {
        & > div {
          display: flex;
          justify-content: space-between;
          input {
            text-align: right;
          }
          span {
            width: 10%;
            text-align: center;
          }
        }
      }
      .location {
        & > div {
          display: flex;
          justify-content: space-between;
          gap: 5%;
          button {
            flex-basis: 1;
            width: 100px;
            background-color: #f2f2f2;
            border: 1px solid #aaa;
            color: #000;
            cursor: pointer;
          }
          button:hover {
            background-color: #dcdcdc;
          }
        }
      }
      .kakaomap {
        padding-top: 0;
        & > div:nth-of-type(1) {
          height: 250px;
        }
      }
      .check {
        & > div {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 20px 14px;
          border: 1px solid #aaa;
          border-radius: 4px;
          .notice {
            p {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 5px;
            }
          }
          .checkitem {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 14px;
            & > div:nth-of-type(2) {
              flex-shrink: 0;
              label {
                font-weight: 400;
                font-size: 14px;
                margin-right: 5px;
              }
            }
          }
          .Required {
            color: red;
          }
        }
      }
    }
    .Required {
      color: red;
    }
  }
  .cta_wrap {
    position: fixed;
    max-width: 460px;
    width: 100%;
    z-index: 10;
    bottom: 0;
    .btn_wrap {
      height: 72px;
      padding: 12px 16px;
      background-color: #fff;
      box-shadow: 0 -2px 12px 0 rgba(33, 37, 41, 0.08);
      button {
        width: 100%;
        height: 48px;
        color: #fff;
        border: 1px solid #00c471;
        border-radius: 4px;
        background-color: #00c471;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
      }
      button:disabled {
        color: #3e4042;
        border: 1px solid #aaa;
        border-radius: 4px;
        background-color: #aaa;
      }
    }
  }
}

.game_detail {
  .content_wrap {
    padding-bottom: 98px;
    .content {
      & > div {
        padding: 30px 16px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        & > h1 {
          font-weight: 600;
          font-size: 18px;
        }
      }
      .title {
        margin-top: 10px;
        h1 {
          font-weight: 600;
          font-size: 22px;
        }
        .location {
          margin-top: 10px;
          font-size: small;
          .copy_location {
            color: #727f88;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        .schedule {
          margin-top: 15px;
          font-size: 16px;
        }
        .members {
          margin-top: 10px;
          font-size: small;
        }
      }
      .game_type {
        .types {
          margin-top: 30px;
          li {
            width: 48%;
            display: inline-flex;
            align-items: center;
            padding: 10px 0;
            img {
              width: 24px;
              height: 24px;
              margin-right: 10px;
            }
          }
        }
      }
      .game_info,
      .manner,
      .map,
      .check {
        & > div:nth-of-type(1) {
          margin-top: 30px;
        }
      }
      .game_info {
        & > div {
          white-space: pre-wrap;
          line-height: 25px;
        }
      }
      .manner {
        .notice {
          p {
            line-height: 20px;
            margin-bottom: 5px;
          }
        }
      }
      .map {
        .kakaomap {
          & > div:nth-of-type(1) {
            height: 250px;
          }
        }
        .location {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding: 15px 15px 12px 15px;
          background-color: #f2f2f2;
          gap: 5px;
          span {
            line-height: 24px;
          }
          a {
            height: 24px;
            font-size: 14px;
            border: 1px solid;
            border-radius: 30px;
            width: 70px;
            text-align: center;
            padding: 4px 0;
            color: #000;
            text-decoration: none;
            cursor: pointer;
          }
        }
      }
      .check {
        .checkitem {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 20px;
          & > div:nth-of-type(2) {
            flex-shrink: 0;
            label {
              margin-right: 5px;
            }
          }
        }
        .Required {
          color: red;
        }
      }
    }
  }
  .cta_wrap {
    position: fixed;
    max-width: 460px;
    width: 100%;
    z-index: 10;
    bottom: 0;
    .hooking {
      height: 36px;
      padding: 8px 16px;
      background-color: #f4faff;
      color: #3e4042;
      font-size: small;
      font-weight: 600;
      line-height: 20px;
      text-align: center;
    }
    .btn_wrap {
      height: 72px;
      padding: 12px 16px;
      background-color: #fff;
      box-shadow: 0 -2px 12px 0 rgba(33, 37, 41, 0.08);
      button {
        width: 100%;
        height: 48px;
        color: #fff;
        border: 1px solid #00c471;
        border-radius: 4px;
        background-color: #00c471;
        font-size: 15px;
        font-weight: 700;
        cursor: pointer;
      }
      button:disabled {
        color: #3e4042;
        border: 1px solid #aaa;
        border-radius: 4px;
        background-color: #aaa;
      }
    }
  }
}

.game_list {
  position: relative;
  min-height: calc(100vh - 186px);
  .introduce {
    // height: 150px;
  }
  .content_wrap {
    padding: 30px 16px;
    h1 {
      font-size: 18px;
      font-weight: 700;
      padding-left: 10px;
      img {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
    p {
      color: #686868;
      margin-top: 10px;
      padding-left: 10px;
    }
    .filter {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 20px;
      padding: 0 10px;
      color: #686868;
      label {
        font-size: 14px;
      }
    }
    .list {
      margin-top: -20px;
      ul {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        li {
          display: flex;
          flex-direction: column;
          gap: 10px;
          margin-bottom: 10px;
          padding: 15px;
          border: 1px solid #f6f7f7;
          background-color: #f6f7f7;
          border-radius: 10px;
          & > div {
            line-height: 16px;
            font-size: 14px;
          }
          .tag {
            font-size: 12px;
            font-weight: 700;
            span {
              padding: 3px 5px;
            }
            .versus {
              margin-left: 7px;
              background-color: #fff;
              border: 1px solid #fff;
              border-radius: 4px;
            }
            .recruiting {
              border: 1px solid #dde8f8;
              border-radius: 4px;
              background-color: #dde8f8;
              color: #0066ff;
            }
            .done {
              border: 1px solid #ffe0e0;
              border-radius: 4px;
              background-color: #ffe0e0;
              color: red;
            }
            .closed {
              border: 1px solid #aaa;
              border-radius: 4px;
              background-color: #aaa;
              color: #fff;
            }
          }
          .title {
            font-size: 16px;
            font-weight: 700;
            margin-top: 10px;
            cursor: pointer;
          }
          .title:hover {
            text-decoration: underline;
            color: #aaa;
          }
          .game_info {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            .schedule {
              font-weight: 700;
              img {
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-right: 5px;
              }
            }
            .location {
              img {
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-right: 5px;
              }
            }
          }
          .members {
            img {
              vertical-align: middle;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }
  }
}
.mypage {
  padding: 30px 16px;
  min-height: calc(100vh - 186px);
  .content_wrap {
    h1 {
      font-size: 18px;
      font-weight: 700;
      img {
        width: 16px;
        height: 16px;
        margin-left: 2px;
      }
    }
    ul {
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      margin-bottom: 20px;
      li {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 10px;
        padding: 15px;
        border: 1px solid #f6f7f7;
        background-color: #f6f7f7;
        border-radius: 10px;
        & > div {
          line-height: 16px;
          font-size: 14px;
        }
        .tag {
          font-size: 12px;
          font-weight: 700;
          span {
            padding: 3px 5px;
          }
          .versus {
            margin-left: 7px;
            background-color: #fff;
            border: 1px solid #fff;
            border-radius: 4px;
          }
          .recruiting {
            border: 1px solid #dde8f8;
            border-radius: 4px;
            background-color: #dde8f8;
            color: #0066ff;
          }
          .done {
            border: 1px solid #ffe0e0;
            border-radius: 4px;
            background-color: #ffe0e0;
            color: red;
          }
          .closed {
            border: 1px solid #aaa;
            border-radius: 4px;
            background-color: #aaa;
            color: #fff;
          }
        }
        .title {
          font-size: 16px;
          font-weight: 700;
          margin-top: 10px;
          cursor: pointer;
        }
        .title:hover {
          text-decoration: underline;
          color: #aaa;
        }
        .game_info {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          .schedule {
            font-weight: 700;
            img {
              width: 16px;
              height: 16px;
              vertical-align: middle;
              margin-right: 5px;
            }
          }
          .location {
            img {
              width: 16px;
              height: 16px;
              vertical-align: middle;
              margin-right: 5px;
            }
          }
        }
        .members {
          img {
            vertical-align: middle;
            width: 16px;
            height: 16px;
          }
        }
        .cancel {
          width: 100px;
          float: right;
        }
      }

      .no_game {
        margin: 20px 0;
      }
    }
    .done_match {
      margin-top: 50px;
    }
  }
}
