@charset "utf-8";

/* mobile */
/* z-index (.modal : 10) (.nav-wrap : 11) */

header {
  position: sticky;
  width: 100%;
  height: 60px;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 9;
  background: #fafafa;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  .header_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 6px 16px;
    & > div {
      height: 48px;
    }
    .logo {
      height: 48px;
    }
    .login {
      color: #1f2937;
      font-size: 18px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}
.gnb_wrap {
  & > div {
    button {
      color: #1f2937;
      left: 0;
    }
    button:hover {
      color: #2670fb;
    }
  }
}
footer {
  background-color: #303740;
  padding: 30px 16px;
  color: #bdbdbd;
  font-size: 12px;
  & > div {
    margin-bottom: 10px;
  }
  .policy {
    font-weight: 700;
    a:not(:last-child)::after {
      content: '|';
      margin: 0 5px;
    }
    a {
      color: #bdbdbd;
      text-decoration: none;
    }
  }
  .info {
    span:not(:last-child)::after {
      content: '|';
      margin: 0 5px;
    }
    a {
      color: #bdbdbd;
    }
  }
}
