body {
  height: 100vh;
  overflow-y: scroll;
  background-color: #eee;
}
#root {
  max-width: 460px;
  min-height: 100vh;
  background-color: #fff;
  scrollbar-width: none;
  margin: 0 auto;
}
#root::-webkit-scrollbar {
  display: none;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 1024px) {
  body {
    background-color: #eef1fc;
    background: url('/public/images/main_background.jpg') no-repeat;
    background-size: cover;
    background-attachment: fixed;
  }
  #root {
    margin: 0 0 0 calc(50vw + 45px);
  }
}
